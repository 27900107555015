import styles from "./Panel.module.css";
import Error from "../../ui/Error";

const Panel = ({ title, size = "", errors = false, children, panelAddition, panelAdditionAction = null }) => {
	return (
		<div className={"panel" + (size ? " " + size : "")}>
			<div className="panelHead">
				<div className="title">{title}</div>
				{panelAddition && (
					<div className={styles.panelAddition} onClick={panelAdditionAction ? () => panelAdditionAction() : null}>
						{panelAddition}
					</div>
				)}
			</div>
			{errors && <Error />}
			{children}
		</div>
	);
};

export default Panel;
