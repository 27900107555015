import cookie from "react-cookies";

export const getTokens = () => {
	return {
		token: process.env.NODE_ENV === "development" ? cookie.load("token") : localStorage.getItem("token"),
		refreshToken: process.env.NODE_ENV === "development" ? cookie.load("refreshToken") : localStorage.getItem("refreshToken")
	};
};

export const removeTokens = () => {
	if (process.env.NODE_ENV === "development") {
		cookie.remove("token", { path: "/", domain: process.env.HOST });
		cookie.remove("refreshToken", { path: "/", domain: process.env.HOST });
	} else {
		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
	}
};

export const setTokens = ({ token, refreshToken }) => {
	if (process.env.NODE_ENV === "development") {
		cookie.save("token", token, { path: "/", domain: process.env.HOST });
		cookie.save("refreshToken", refreshToken, { path: "/", domain: process.env.HOST });
	} else {
		localStorage.setItem("token", token);
		localStorage.setItem("refreshToken", refreshToken);
	}
};
