import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserList from "../../components/Users/UserList/UserList";
import { getUsers } from "../../features/user/userSlice";
import Panel from "../../hoc/Panel/Panel";

const Users = () => {
	const users = useSelector((state) => state.user.entries);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!users) {
			dispatch(getUsers());
		}
	}, [dispatch, users]);

	return (
		<Panel size="full large" title="Benutzer">
			{users && <UserList users={users} />}
		</Panel>
	);
};

export default Users;
