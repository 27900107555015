import { createSlice } from "@reduxjs/toolkit";

const namespace = "message";

const initialState = {
	success: null,
	error: null,
	tempSuccess: null,
	tempError: null
};

const messageSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setError: (state, { payload }) => {
			state.error = payload;
		},
		clearError: (state) => {
			state.error = null;
		},
		setSuccess: (state, { payload }) => {
			state.success = payload;
		},
		setTempError: (state, { payload }) => {
			state.tempError = payload;
		},
		setTempSuccess: (state, { payload }) => {
			state.tempSuccess = payload;
		},
		clearAllMessages: (state) => {
			state.success = null;
			state.error = null;
			state.tempSuccess = null;
			state.tempError = null;
		}
	}
});

export const { setError, setSuccess, setTempError, setTempSuccess, clearAllMessages, clearError } = messageSlice.actions;

export default messageSlice.reducer;
