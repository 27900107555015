import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddEarsAccelerate from "./AddEarsAccelerate/AddEarsAccelerate";

const TaskEarsAccelerate = ({ userId, taskClass }) => {
	const taskEarsAccelerate = useSelector((state) => state.flight.taskEarsAccelerate);
	const [add, setAdd] = useState(false);

	const readyForExamination = taskEarsAccelerate.length >= 3 && taskEarsAccelerate.slice(-3).filter((entry) => entry.result === "ok").length === 3;

	return (
		<div className={taskClass}>
			<Task title="Ohreneinklappen und Beschleunigen" flights={taskEarsAccelerate} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddEarsAccelerate userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskEarsAccelerate;
