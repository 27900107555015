import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";

const namespace = "userData";

const initialState = {
	firstname: "",
	lastname: "",
	webAppMenu: null
};

export const getUserData = createAsyncThunk(`${namespace}/getUserData`, async (_, { rejectWithValue }) => {
	try {
		const { data } = await axios.get("auth/getUserData");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const userDataSlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getUserData.fulfilled, (state, { payload }) => {
				state.firstname = payload.firstname;
				state.lastname = payload.lastname;
				state.webAppMenu = payload.webAppMenu;
			})
			.addCase(logout, (state, action) => {
				return initialState;
			});
	}
});

export default userDataSlice.reducer;
