import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setTempSuccess } from "../message/messageSlice";

const namespace = "user";

const initialState = {
	entries: null,
	user: null
};

export const getUsers = createAsyncThunk(`${namespace}/getUsers`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("user");
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const getUser = createAsyncThunk(`${namespace}/getUser`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("user/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const saveUser = createAsyncThunk(`${namespace}/saveUser`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.patch("user/" + payload.id, payload.data);
		dispatch(setTempSuccess(`Der Benutzer «${payload.data.firstname} ${payload.data.lastname}» wurde erfolgreich gespeichert.`));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createUser = createAsyncThunk(`${namespace}/createUser`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("user", payload);
		dispatch(setTempSuccess(`Der Benutzer «${payload.firstname} ${payload.lastname}» wurde erfolgreich erstellt.`));
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const userSlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: {
		[getUsers.fulfilled]: (state, { payload }) => {
			state.entries = payload;
		},
		[getUsers.rejected]: (state, { payload }) => {
			state.entries = [];
		},
		[getUser.fulfilled]: (state, { payload }) => {
			state.user = payload;
		},
		[saveUser.fulfilled]: (state, { payload }) => {
			state.entries = state.entries.map((user) => {
				if (user.id === payload.id) {
					return payload;
				} else {
					return user;
				}
			});
		},
		[createUser.fulfilled]: (state, { payload }) => {
			state.entries.push(payload);
		},
		[logout]: (state) => {
			return initialState;
		}
	}
});

//export const {  } = userSlice.actions;

export default userSlice.reducer;
