import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import TempSuccess from "../../ui/TempSuccess";
import TempError from "../../ui/TempError";
import Menu from "../../components/Menu/Menu";
import Spinner from "../../ui/Spinner";
import AlpinaLogo from "../../ui/AlpinaLogo";
import Ask from "../Ask/Ask";

const Layout = () => {
	const loading = useSelector((state) => state.loading);

	return (
		<Fragment>
			<TempSuccess />
			<TempError />
			<div id="topBar">
				{process.env.REACT_APP_ENV === "development" && <div className="development">DEV</div>}
				<Menu />
				<AlpinaLogo />
			</div>
			<main>
				<Outlet />
			</main>
			<Ask />
			{loading && <Spinner />}
		</Fragment>
	);
};

export default Layout;
