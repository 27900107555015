import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddTaskNodding from "./AddTaskNodding/AddTaskNodding";

const TaskNodding = ({ userId, taskClass }) => {
	const taskNodding = useSelector((state) => state.flight.taskNodding);
	const [add, setAdd] = useState(false);

	const readyForExamination = taskNodding.length >= 3 && taskNodding.slice(-3).filter((entry) => entry.result === "ok").length === 3;

	return (
		<div className={taskClass}>
			<Task title="Nicken" flights={taskNodding} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddTaskNodding userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskNodding;
