import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlightPanel from "../../components/FlightPanel/FlightPanel";
import UserSelector from "../../components/UserSelector/UserSelector";
import { getFlights } from "../../features/flight/flightSlice";
import { getUsers } from "../../features/user/userSlice";

import * as routes from "../../shared/routes";

const Flight = () => {
	const users = useSelector((state) => state.user.entries);
	const userId = useSelector((state) => state.flight.userId);
	const [changeUser, setChangeUser] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!users) {
			dispatch(getUsers());
		}
	}, [dispatch, users]);

	useEffect(() => {
		if (userId) {
			dispatch(getFlights(userId));
		}
	}, [dispatch, userId]);

	const cancelSelectUser = () => {
		setChangeUser(false);
		if (!userId) {
			navigate(routes.START);
		}
	};

	const setUser = (id) => {
		dispatch(getFlights(id));
		setChangeUser(false);
	};

	if (!users) {
		return;
	}

	return userId && !changeUser ? <FlightPanel userId={userId} changeUser={setChangeUser} /> : <UserSelector userId={userId} cancel={cancelSelectUser} setUser={setUser} />;
};

export default Flight;
