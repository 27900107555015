import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { authenticated } from "../../features/auth/authSlice";

import * as routes from "../../shared/routes";

const RequireAuth = () => {
	const isAuthenticated = useSelector(authenticated);
	const location = useLocation();
	return isAuthenticated ? <Outlet /> : <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
};

export default RequireAuth;
