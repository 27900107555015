import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";

const namespace = "flight";

const initialState = {
	userId: 0,
	taskDoubleCircle: null,
	taskEarsAccelerate: null,
	taskEarsDirection: null,
	taskEight: null,
	taskNodding: null,
	taskRoll: null,
	taskSideFolding: null,
	taskLanding: null
};

export const getFlights = createAsyncThunk(`${namespace}/getFlights`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.get("flight/" + payload);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createDoubleCircle = createAsyncThunk(`${namespace}/createDoubleCircle`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/taskDoubleCircle", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createEight = createAsyncThunk(`${namespace}/createEight`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/taskEight", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createSideFolding = createAsyncThunk(`${namespace}/createSideFolding`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/sideFolding", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createEarsDirection = createAsyncThunk(`${namespace}/createEarsDirection`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/earsDirection", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createEarsAccelerate = createAsyncThunk(`${namespace}/createEarsAccelerate`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/earsAccelerate", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createNodding = createAsyncThunk(`${namespace}/createNodding`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/nodding", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createRoll = createAsyncThunk(`${namespace}/createRoll`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/roll", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

export const createLanding = createAsyncThunk(`${namespace}/createLanding`, async (payload, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await axios.post("flight/" + payload.id + "/landing", payload.data);
		return data;
	} catch (error) {
		return rejectWithValue(error.response.status);
	}
});

const flightSlice = createSlice({
	name: namespace,
	initialState,
	extraReducers: {
		[getFlights.fulfilled]: (state, { payload }) => {
			state.userId = payload.userId;
			state.taskDoubleCircle = payload.taskDoubleCircle;
			state.taskEarsAccelerate = payload.taskEarsAccelerate;
			state.taskEarsDirection = payload.taskEarsDirection;
			state.taskEight = payload.taskEight;
			state.taskNodding = payload.taskNodding;
			state.taskRoll = payload.taskRoll;
			state.taskSideFolding = payload.taskSideFolding;
			state.taskLanding = payload.taskLanding;
		},
		[createDoubleCircle.fulfilled]: (state, { payload }) => {
			state.taskDoubleCircle = payload.taskDoubleCircle;
		},
		[createEight.fulfilled]: (state, { payload }) => {
			state.taskEight = payload.taskEight;
		},
		[createSideFolding.fulfilled]: (state, { payload }) => {
			state.taskSideFolding = payload.taskSideFolding;
		},
		[createEarsDirection.fulfilled]: (state, { payload }) => {
			state.taskEarsDirection = payload.taskEarsDirection;
		},
		[createEarsAccelerate.fulfilled]: (state, { payload }) => {
			state.taskEarsAccelerate = payload.taskEarsAccelerate;
		},
		[createNodding.fulfilled]: (state, { payload }) => {
			state.taskNodding = payload.taskNodding;
		},
		[createRoll.fulfilled]: (state, { payload }) => {
			state.taskRoll = payload.taskRoll;
		},
		[createLanding.fulfilled]: (state, { payload }) => {
			state.taskLanding = payload.taskLanding;
		},
		[logout]: (state) => {
			return initialState;
		}
	}
});

//export const {  } = userSlice.actions;

export default flightSlice.reducer;
