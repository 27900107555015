import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddEarsDirection from "./AddEarsDirection/AddEarsDirection";

const TaskEarsDirection = ({ userId, taskClass }) => {
	const taskEarsDirection = useSelector((state) => state.flight.taskEarsDirection);
	const [add, setAdd] = useState(false);

	const readyForExamination = taskEarsDirection.length >= 3 && taskEarsDirection.slice(-3).filter((entry) => entry.result === "ok").length === 3;

	return (
		<div className={taskClass}>
			<Task title="Ohreneinklappen mit Richtungswechsel" flights={taskEarsDirection} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddEarsDirection userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskEarsDirection;
