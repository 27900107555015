import TeaserButton from "../../components/TeaserButton/TeaserButton";
import Panel from "../../hoc/Panel/Panel";
import * as routes from "../../shared/routes";

import { ReactComponent as UsersIcon } from "../../assets/images/multy-user.svg";
import { ReactComponent as ParaglidingIcon } from "../../assets/images/paragliding.svg";

const Home = () => {
	return (
		<Panel title="Start" size="large">
			<div className="panelBottom">
				<div className="teaserButtons">
					<TeaserButton name="Benutzer" url={routes.USERS} icon={<UsersIcon />} />
					<TeaserButton name="Ausbildung" url={routes.EDUCATION} icon={<ParaglidingIcon />} />
				</div>
			</div>
		</Panel>
	);
};

export default Home;
