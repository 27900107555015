import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "./TeaserButton.module.css";

const TeaserButton = ({ url, name, icon }) => {
	const navigate = useNavigate();
	return (
		<div className={classes.teaserButton} onClick={() => navigate(url)}>
			{icon}
			<div>{name}</div>
		</div>
	);
};

export default TeaserButton;
