import { useState } from "react";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { createEarsAccelerate } from "../../../../features/flight/flightSlice";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import styles from "./AddEarsAccelerate.module.css";

let timer = null;
const minTime = 10000;

const AddEarsAccelerate = ({ userId, cancel }) => {
	const dispatch = useDispatch();
	const [startTime, setStartTime] = useState(0);
	const [time, setTime] = useState(0);
	const [result, setResult] = useState({
		result: "",
		failed: {
			time: false,
			direction: false,
			tooLittle: false
		},
		comment: ""
	});

	const addTime = () => {
		setTime((prev) => prev + 100);
	};

	const start = () => {
		setStartTime(new Date().getTime());
		timer = setInterval(addTime, 100);
	};

	const stop = () => {
		const executionTime = new Date().getTime() - startTime;
		setTime(executionTime);
		setResult((prev) => ({ ...prev, result: executionTime >= minTime ? "ok" : "failed", failed: { ...result.failed, time: executionTime < minTime } }));
		setStartTime(null);
		clearInterval(timer);
	};

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;
		if (fieldname === "failed") {
			const checked = e.target.checked;
			setResult((prev) => ({ ...prev, failed: { ...prev.failed, [value]: checked } }));
		} else {
			setResult((prev) => ({ ...prev, [fieldname]: value }));
		}
	};

	const save = () => {
		dispatch(createEarsAccelerate({ id: userId, data: { ...result } }));
		cancel();
	};

	return (
		<Window>
			<Panel title="Ohreneinklappen und Beschleunigen">
				<div className={styles.panel}>
					<div className={styles.timerButton}>
						{startTime === 0 && (
							<button className={styles.timerButtonGreen} type="button" onClick={start}>
								Start
							</button>
						)}
						{startTime > 0 && (
							<button className={time > minTime ? styles.timerButtonGreen : styles.timerButtonRed} type="button" onClick={stop}>
								Stop ({(time / 1000).toFixed(1)}s)
							</button>
						)}
					</div>
					{startTime === null && (
						<div>
							<div>Zeit: {(time / 1000).toFixed(1)} Sekunden</div>
							<label className="radio">
								<input type="radio" name="result" value="ok" checked={result.result === "ok"} onChange={change} />
								<span>Aufgabe erfüllt</span>
							</label>
							<label className="radio">
								<input type="radio" name="result" value="failed" checked={result.result === "failed"} onChange={change} />
								<span>Aufgabe nicht erfüllt</span>
							</label>
							{result.result === "failed" && (
								<div className={styles.embed}>
									<label className="radio">
										<input type="checkbox" name="failed" value="time" checked={result.failed.time} onChange={change} />
										<span>Zeit nicht eingehalten</span>
									</label>
									<label className="radio">
										<input type="checkbox" name="failed" value="direction" checked={result.failed.direction} onChange={change} />
										<span>Flugachse nicht eingehalten</span>
									</label>
									<label className="radio">
										<input type="checkbox" name="failed" value="tooLittle" checked={result.failed.tooLittle} onChange={change} />
										<span>Zuwenig eingeklappt</span>
									</label>
								</div>
							)}
							<label>
								<span>Kommentar</span>
								<TextareaAutosize name="comment" value={result.comment} onChange={change} minRows={1} />
							</label>
						</div>
					)}
					<div className="buttons">
						<button type="button" onClick={() => cancel()}>
							Abbrechen
						</button>
						<button type="button" onClick={save} disabled={startTime !== null}>
							Speichern
						</button>
					</div>
				</div>
			</Panel>
		</Window>
	);
};

export default AddEarsAccelerate;
