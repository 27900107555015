import styles from "./ListHeader.module.css";

import { ReactComponent as Down } from "../../../../assets/images/icon_chevron_down.svg";
import { ReactComponent as Up } from "../../../../assets/images/icon_chevron_up.svg";

const ListHeader = ({ orderBy, setOrderBy, fields }) => {
	const row = fields.map((field) => {
		const orderIcon = orderBy.order === "asc" ? <Up /> : <Down />;
		return (
			<div onClick={() => setOrderBy(field.key)} className={orderBy.key === field.key ? "" : styles.disabled} key={field.key}>
				{field.name}
				{orderIcon}
			</div>
		);
	});

	return <div className={styles.header}>{row}</div>;
};

export default ListHeader;
