import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddTaskLanding from "./AddTaskLanding/AddTaskLanding";

const TaskLanding = ({ userId, taskClass }) => {
	const taskLanding = useSelector((state) => state.flight.taskLanding);
	const [add, setAdd] = useState(false);

	const goodLandings = taskLanding.filter((entry) => entry.result === "ok").length;
	const badLandings = taskLanding.filter((entry) => entry.result === "failed").length;
	const readyForExamination = goodLandings + badLandings > 30 && taskLanding.slice(-10).filter((entry) => entry.result === "ok").length > 8;

	return (
		<div className={taskClass}>
			<Task title="Landung" flights={taskLanding} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddTaskLanding userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskLanding;
