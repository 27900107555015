import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAllMessages } from "../../../features/message/messageSlice";
import { createUser, getUser, saveUser } from "../../../features/user/userSlice";
import Panel from "../../../hoc/Panel/Panel";
import Window from "../../../hoc/Window/Window";

import * as routes from "../../../shared/routes";
import styles from "./UserDetail.module.css";

const newUser = {
	id: 0,
	firstname: "",
	lastname: "",
	eMail: "",
	shv: "",
	userType: ""
};

const UserDetail = ({ id, userTypes }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user);
	const tempSuccess = useSelector((state) => state.message.tempSuccess);
	const [personData, setPersonData] = useState(null);

	useEffect(() => {
		if (id) {
			dispatch(getUser(id));
		} else {
			setPersonData({ ...newUser });
		}
	}, [dispatch, id]);

	useEffect(() => {
		dispatch(clearAllMessages());
	}, [dispatch]);

	useEffect(() => {
		if (tempSuccess) {
			navigate(routes.USERS);
		}
	}, [tempSuccess, navigate]);

	useEffect(() => {
		return () => setPersonData(null);
	}, []);

	useEffect(() => {
		if (id) {
			setPersonData({ ...user });
		}
	}, [user, id]);

	if (!personData || personData.id !== id) {
		return null;
	}

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;
		if (fieldname === "shv" && !value.match(/^\d{0,5}$/)) {
			return;
		}
		setPersonData((prev) => ({ ...prev, [fieldname]: value }));
	};

	const save = () => {
		if (id) {
			dispatch(saveUser({ id, data: personData }));
		} else {
			dispatch(createUser(personData));
		}
	};

	return (
		<Window>
			<Panel title={id ? "Benutzer bearbeiten" : "Neuer Benutzer"} size="medium">
				<div className={styles.panelContent}>
					<form>
						<div className={styles.twoCols}>
							<label className={styles.fieldLabel}>
								<span>Vorname</span>
								<input type="text" name="firstname" value={personData.firstname} onChange={change} maxLength={64} />
							</label>
							<label className={styles.fieldLabel}>
								<span>Nachname</span>
								<input type="text" name="lastname" value={personData.lastname} onChange={change} maxLength={64} />
							</label>
						</div>
						<div className={styles.twoCols}>
							<label className={styles.fieldLabel}>
								<span>E-Mail</span>
								<input type="text" name="eMail" value={personData.eMail} onChange={change} maxLength={128} />
							</label>
							<label className={styles.fieldLabel}>
								<span>SHV-Nummer</span>
								<input type="text" name="shv" value={personData.shv} onChange={change} maxLength={5} />
							</label>
						</div>
						<div className={styles.twoCols}>
							<label className={styles.fieldLabel}>
								<span>Typ</span>
								{userTypes.map((entry) => (
									<label className={styles.radioLabel} key={entry.type}>
										<input type="radio" name="userType" value={entry.type} checked={entry.type === personData.userType} onChange={change} />
										<span>{entry.name}</span>
									</label>
								))}
							</label>
						</div>
					</form>
					<div className="buttons">
						<button type="button" onClick={() => navigate(routes.USERS)}>
							Abbrechen
						</button>
						<button type="button" onClick={save} disabled={personData.firstname.trim().length < 2 || personData.lastname.trim().length < 2 || !personData.userType}>
							Speichern
						</button>
					</div>
				</div>
			</Panel>
		</Window>
	);
};

export default UserDetail;
