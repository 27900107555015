import styles from "./Flight.module.css";

const Flight = ({ result }) => {
	const classNames = [styles.flight];
	switch (result) {
		case "ok":
			classNames.push(styles.ok);
			break;
		case "failed":
			classNames.push(styles.failed);
			break;
		case "notRated":
			classNames.push(styles.notRated);
			break;

		default:
	}
	return <div className={classNames.join(" ")}></div>;
};

export default Flight;
