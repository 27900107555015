import { useSelector } from "react-redux";
import Panel from "../../hoc/Panel/Panel";
import TaskDoubleCircle from "./TaskDoubleCircle/TaskDoubleCircle";
import TaskEarsAccelerate from "./TaskEarsAccelerate/TaskEarsAccelerate";
import TaskEarsDirection from "./TaskEarsDirection/TaskEarsDirection";
import TaskEight from "./TaskEight/TaskEight";
import TaskNodding from "./TaskNodding/TaskNodding";
import TaskRoll from "./TaskRoll/TaskRoll";
import TaskSideFolding from "./TaskSideFolding/TaskSideFolding";
import styles from "./FlightPanel.module.css";
import TaskLanding from "./TaskLanding/TaskLanding";

const FlightPanel = ({ userId, changeUser }) => {
	const users = useSelector((state) => state.user.entries);
	const user = users.find((entry) => entry.id === userId);
	return (
		<Panel title="Ausbildung" panelAddition={`${user.firstname} ${user.lastname}`} panelAdditionAction={() => changeUser(true)}>
			<div className={styles.panel}>
				<TaskDoubleCircle userId={userId} taskClass={styles.task} />
				<TaskEight userId={userId} taskClass={styles.task} />
				<TaskSideFolding userId={userId} taskClass={styles.task} />
				<TaskEarsDirection userId={userId} taskClass={styles.task} />
				<TaskEarsAccelerate userId={userId} taskClass={styles.task} />
				<TaskNodding userId={userId} taskClass={styles.task} />
				<TaskRoll userId={userId} taskClass={styles.task} />
				<TaskLanding userId={userId} taskClass={styles.task} />
			</div>
		</Panel>
	);
};

export default FlightPanel;
