import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import loadingReducer from "../features/loading/loadingSlice";
import messageReducer from "../features/message/messageSlice";
import userDataReducer from "../features/userData/userDataSlice";
import askReducer from "../features/ask/askSlice";
import userReducer from "../features/user/userSlice";
import flightReducer from "../features/flight/flightSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		loading: loadingReducer,
		message: messageReducer,
		userData: userDataReducer,
		ask: askReducer,
		user: userReducer,
		flight: flightReducer
	},
	middleware: [...getDefaultMiddleware({ immutableCheck: false })]
});
