import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddEight from "./AddEight/AddEight";

const TaskEight = ({ userId, taskClass }) => {
	const taskEight = useSelector((state) => state.flight.taskEight);
	const [add, setAdd] = useState(false);

	const readyForExamination = taskEight.length >= 3 && taskEight.slice(-3).filter((entry) => entry.result === "ok").length === 3;

	return (
		<div className={taskClass}>
			<Task title="Acht" flights={taskEight} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddEight userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskEight;
