import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { tryAutoSignUp } from "./features/auth/authSlice";
import * as routes from "./shared/routes";

import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import Layout from "./hoc/Layout/Layout";
import { useEffect } from "react";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import RequireNoAuth from "./components/RequireNoAuth/RequireNoAuth";
import Users from "./containers/Users/Users";
import Flight from "./containers/Flight/Flight";

const App = () => {
	const autoSignupTried = useSelector((state) => state.auth.autoSignupTried);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!autoSignupTried) {
			dispatch(tryAutoSignUp());
		}
	}, [autoSignupTried, dispatch]);

	if (!autoSignupTried) {
		return null;
	}

	return (
		<Routes>
			<Route element={<Layout />}>
				<Route element={<RequireAuth />}>
					<Route path={routes.START} element={<Home />} />
					<Route path={routes.USERS + "/:id"} element={<Users />} />
					<Route path={routes.USERS} element={<Users />} />
					<Route path={routes.EDUCATION} element={<Flight />} />
				</Route>
				<Route element={<RequireNoAuth />}>
					<Route path={routes.LOGIN} element={<Login />} />
				</Route>
			</Route>
		</Routes>
	);
};

export default App;
