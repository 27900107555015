import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddTaskRoll from "./AddTaskRoll/AddTaskRoll";

const TaskRoll = ({ userId, taskClass }) => {
	const taskRoll = useSelector((state) => state.flight.taskRoll);
	const [add, setAdd] = useState(false);

	const readyForExamination = taskRoll.length >= 3 && taskRoll.slice(-3).filter((entry) => entry.result === "ok").length === 3;

	return (
		<div className={taskClass}>
			<Task title="Rollen" flights={taskRoll} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddTaskRoll userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskRoll;
