import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "../../shared/axios";

import { setTokens, getTokens, removeTokens } from "../../shared/authFunctions";

import { setError, clearAllMessages } from "../message/messageSlice";
import { getUserData } from "../userData/userDataSlice";

const namespace = "auth";

const initialState = {
	token: null,
	refreshToken: null,
	autoSignupTried: false
};

export const logout = createAction(`${namespace}/logout`);

export const authenticated = (state) => state.auth.token !== null;

export const login = createAsyncThunk(`${namespace}/login`, async (authData, { dispatch, rejectWithValue }) => {
	dispatch(clearAllMessages());
	try {
		const { data } = await axios.post("auth/login", authData);
		dispatch(getUserData());
		return data;
	} catch (error) {
		if (error.response.status === 401) {
			dispatch(setError("Die E-Mail Adresse oder das Passwort ist falsch."));
		}
		return rejectWithValue(error.response.status);
	}
});

export const tryAutoSignUp = createAsyncThunk(`${namespace}/autoSignup`, async (_, { dispatch, rejectWithValue }) => {
	const { token } = getTokens();
	if (token) {
		try {
			const { data } = await axios.get("auth/autoSignup");
			dispatch(getUserData());
			return data;
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logout());
			}
			return rejectWithValue(error.response.status);
		}
	} else {
		return rejectWithValue(401);
	}
});

const authSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setRefreshToken(state, { payload }) {
			setTokens(payload);
			state.token = payload.token;
			state.refreshToken = payload.refreshToken;
		}
	},
	extraReducers: {
		[logout]: (state) => {
			removeTokens();
			return initialState;
		},
		[login.pending]: (state) => {
			// Set loading to true
		},
		[login.fulfilled]: (state, { payload }) => {
			setTokens(payload);
			state.token = payload.token;
			state.refreshToken = payload.refreshToken;
			state.autoSignupTried = true;
		},
		[login.rejected]: (state, { payload }) => {},
		[tryAutoSignUp.fulfilled]: (state, { payload }) => {
			const { token, refreshToken } = getTokens();
			state.token = token;
			state.refreshToken = refreshToken;
			state.autoSignupTried = true;
		},
		[tryAutoSignUp.rejected]: (state) => {
			state.autoSignupTried = true;
		}
	}
});

export const { setRefreshToken } = authSlice.actions;

export default authSlice.reducer;
