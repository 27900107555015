import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import SearchFilter from "../../SearchFilter/SearchFilter";
import UserDetail from "../UserDetail/UserDetail";
import styles from "./UserList.module.css";
import UserListItem from "./UserListItem/UserListItem";
import ListHeader from "./ListHeader/ListHeader";

import * as routes from "../../../shared/routes";

const fields = [
	{
		key: "lastname",
		name: "Nachname",
		search: true
	},
	{
		key: "firstname",
		name: "Vorname",
		search: true
	},
	{
		key: "shv",
		name: "SHV-Nummer",
		type: "number",
		search: true
	},
	{
		key: "userType",
		name: "Typ",
		search: false
	}
];

const userTypes = [
	{
		type: "admin",
		name: "Administrator"
	},
	{
		type: "student",
		name: "Flugschüler"
	},
	{
		type: "pilot",
		name: "Brevetierter Pilot"
	}
];

const UserList = ({ users }) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [orderBy, setOrderBy] = useState({
		key: fields[0].key,
		order: "asc"
	});
	const [search, setSearch] = useState("");

	const searchFields = fields.filter((entry) => entry.search).map((entry) => entry.key);

	const contains = (row, fields) => {
		if (search.trim().length < 3) {
			return true;
		}
		const searchStrings = search.split(" ");

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			const found = fields.reduce((isFound, field) => {
				return isFound || (row[field] && row[field].toString().toLowerCase().indexOf(search) > -1);
			}, false);
			if (found) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const changeOrder = (key) => {
		setOrderBy((prev) => {
			if (prev.key === key) {
				return { ...prev, order: prev.order === "asc" ? "desc" : "asc" };
			} else {
				return { ...prev, key };
			}
		});
	};

	const sortedUsers = [...users]
		.filter((entry) => contains(entry, searchFields))
		.sort((a, b) => {
			const isNumber = fields.find((field) => field.key === orderBy.key)?.type === "number";
			if (isNumber) {
				return orderBy.order === "asc" ? a[orderBy.key] - b[orderBy.key] : b[orderBy.key] - a[orderBy.key];
			}
			const field1 = a[orderBy.key] === null ? "" : a[orderBy.key].trim().toLowerCase();
			const field2 = b[orderBy.key] === null ? "" : b[orderBy.key].trim().toLowerCase();
			return orderBy.order === "asc" ? field1.localeCompare(field2) : field2.localeCompare(field1);
		})
		.map((user) => ({ ...user, userType: userTypes.find((userType) => userType.type === user.userType).name }));

	return (
		<Fragment>
			<div className={styles.content}>
				<div className={styles.search}>
					<SearchFilter filterText={search} setFilterText={setSearch} placeholder="Suche nach Nachname, Vorname, SHV-Nummer" />
					<button type="button" onClick={() => navigate(routes.USERS + "/0")}>
						Neuer Benutzer
					</button>
				</div>
				<ListHeader fields={fields} orderBy={orderBy} setOrderBy={changeOrder} />
				<div className={styles.list}>
					<Virtuoso style={{ height: "100%" }} data={sortedUsers} itemContent={(index, user) => <UserListItem index={index} user={user} fields={fields.map((entry) => entry.key)} />} fixedItemHeight={32} increaseViewportBy={{ top: 500, bottom: 500 }} />
				</div>
			</div>
			{id && <UserDetail id={parseInt(id, 10)} userTypes={userTypes} />}
		</Fragment>
	);
};

export default UserList;
