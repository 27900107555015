import { useState } from "react";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { createEarsDirection } from "../../../../features/flight/flightSlice";
import Panel from "../../../../hoc/Panel/Panel";
import Window from "../../../../hoc/Window/Window";
import styles from "./AddEarsDirection.module.css";

const AddEarsDirection = ({ userId, cancel }) => {
	const dispatch = useDispatch();
	const [result, setResult] = useState({
		result: "",
		failed: {
			direction: false,
			tooLittle: false
		},
		comment: ""
	});

	const change = (e) => {
		const fieldname = e.target.name;
		const value = e.target.value;
		if (fieldname === "failed") {
			const checked = e.target.checked;
			setResult((prev) => ({ ...prev, failed: { ...prev.failed, [value]: checked } }));
		} else {
			setResult((prev) => ({ ...prev, [fieldname]: value }));
		}
	};

	const save = () => {
		dispatch(createEarsDirection({ id: userId, data: { ...result } }));
		cancel();
	};

	return (
		<Window>
			<Panel title="Ohreneinklappen mit Richtungswechsel">
				<div className={styles.panel}>
					<div>
						<label className="radio">
							<input type="radio" name="result" value="ok" checked={result.result === "ok"} onChange={change} />
							<span>Aufgabe erfüllt</span>
						</label>
						<label className="radio">
							<input type="radio" name="result" value="failed" checked={result.result === "failed"} onChange={change} />
							<span>Aufgabe nicht erfüllt</span>
						</label>
						{result.result === "failed" && (
							<div className={styles.embed}>
								<label className="radio">
									<input type="checkbox" name="failed" value="direction" checked={result.failed.direction} onChange={change} />
									<span>Keine 90 Grad Richtungsänderungen</span>
								</label>
								<label className="radio">
									<input type="checkbox" name="failed" value="tooLittle" checked={result.failed.tooLittle} onChange={change} />
									<span>Zuwenig eingeklappt</span>
								</label>
							</div>
						)}
						<label>
							<span>Kommentar</span>
							<TextareaAutosize name="comment" value={result.comment} onChange={change} minRows={1} />
						</label>
					</div>
					<div className="buttons">
						<button type="button" onClick={() => cancel()}>
							Abbrechen
						</button>
						<button type="button" onClick={save} disabled={!result.result}>
							Speichern
						</button>
					</div>
				</div>
			</Panel>
		</Window>
	);
};

export default AddEarsDirection;
