import { useState } from "react";
import { useSelector } from "react-redux";
import Task from "../../../hoc/Task/Task";
import AddSideFolding from "./AddSideFolding/AddSideFolding";

const TaskSideFolding = ({ userId, taskClass }) => {
	const taskSideFolding = useSelector((state) => state.flight.taskSideFolding);
	const [add, setAdd] = useState(false);

	const readyForExamination = taskSideFolding.length >= 3 && taskSideFolding.slice(-3).filter((entry) => entry.result === "ok").length === 3;

	return (
		<div className={taskClass}>
			<Task title="Seitenklapper stabilisiert" flights={taskSideFolding} add={() => setAdd(true)} readyForExamination={readyForExamination} />
			{add && <AddSideFolding userId={userId} cancel={() => setAdd(false)} />}
		</div>
	);
};

export default TaskSideFolding;
