import Flight from "./Flight/Flight";
import styles from "./Task.module.css";

const Task = ({ title, flights, add, readyForExamination = null }) => {
	return (
		<div className={styles.task}>
			<div>
				<h4>
					{readyForExamination && "😊 "}
					{title}
				</h4>
				<div className={styles.flightList}>{flights.length > 0 ? flights.map((task) => <Flight key={task.id} result={task.result} />) : "-"}</div>
			</div>
			<button type="button" onClick={() => add()}>
				+
			</button>
		</div>
	);
};

export default Task;
