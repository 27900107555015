import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { authenticated, logout } from "../../features/auth/authSlice";
import * as routes from "../../shared/routes";

import { ReactComponent as Logout } from "../../assets/images/logout.svg";

const menu = [
	{
		url: routes.USERS,
		name: "Benutzer"
	},
	{
		url: routes.EDUCATION,
		name: "Ausbildung"
	}
];

const Menu = () => {
	const isAuthenticated = useSelector(authenticated);
	const dispatch = useDispatch();
	const location = useLocation();

	if (!isAuthenticated) {
		return null;
	}

	return (
		<ul id="menu">
			{menu.map((entry) => (
				<li key={entry.url}>
					<Link className={location.pathname.indexOf(entry.url) === 0 ? "selected" : ""} to={entry.url}>
						{entry.name}
					</Link>
				</li>
			))}
			<li>
				<Logout onClick={() => dispatch(logout())} />
			</li>
		</ul>
	);
};

export default Menu;
