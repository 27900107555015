import axios from "axios";
import { store } from "../app/store";
import { setRefreshToken } from "../features/auth/authSlice";
import { getTokens } from "./authFunctions";

const instance = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_URL
});

// Add a request interceptor
instance.interceptors.request.use(
	(config) => {
		const { token } = getTokens();
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		if (process.env.REACT_APP_ENV === "development") {
			console.group("instance.interceptors.response");
			console.log(originalRequest);
		}
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			if (process.env.REACT_APP_ENV === "development") {
				console.log("Getting a new token with the refresh token");
			}
			const { refreshToken } = getTokens();
			return axios
				.post(process.env.REACT_APP_AXIOS_URL + "auth/refreshToken", {
					refreshToken: refreshToken
				})
				.then((res) => {
					if (res.status === 201) {
						if (process.env.REACT_APP_ENV === "development") {
							console.log("Juhheee, new TOKEN");
							console.log(res);
						}
						store.dispatch(setRefreshToken({ token: res.data.token, refreshToken: res.data.refreshToken }));
						originalRequest.headers["Authorization"] = "Bearer " + res.data.token;
						originalRequest.updatedToken = { token: res.data.token, refreshToken: res.data.refreshToken, serverTime: res.data.serverTime };
						if (process.env.REACT_APP_ENV === "development") {
							console.groupEnd();
						}
						return axios(originalRequest);
					}
				});
		}
		console.groupEnd();
		// return Error object with Promise
		return Promise.reject(error);
	}
);

export default instance;
