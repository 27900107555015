import { useNavigate } from "react-router-dom";
import styles from "./UserListItem.module.css";

import * as routes from "../../../../shared/routes";

const UserListItem = ({ index, user, fields }) => {
	const navigate = useNavigate();
	const classNames = [styles.row];
	if (index % 2) {
		classNames.push(styles.alt);
	}

	const row = fields.map((fieldname) => <div key={fieldname}>{user[fieldname]}</div>);

	return (
		<div className={classNames.join(" ")} onClick={() => navigate(routes.USERS + "/" + user.id)}>
			{row}
		</div>
	);
};

export default UserListItem;
