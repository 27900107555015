import { useState } from "react";
import { useSelector } from "react-redux";
import Panel from "../../hoc/Panel/Panel";
import Window from "../../hoc/Window/Window";
import SearchFilter from "../SearchFilter/SearchFilter";

import styles from "./UserSelector.module.css";

const fields = [
	{
		key: "lastname",
		name: "Nachname",
		search: true
	},
	{
		key: "firstname",
		name: "Vorname",
		search: true
	},
	{
		key: "shv",
		name: "SHV-Nummer",
		type: "number",
		search: true
	}
];

const UserSelector = ({ cancel, setUser }) => {
	const users = useSelector((state) => state.user.entries);
	const students = users.filter((user) => user.userType === "student");
	const [selectedUser, setSelectedUser] = useState(0);

	const [search, setSearch] = useState("");

	const searchFields = fields.filter((entry) => entry.search).map((entry) => entry.key);

	const contains = (row, fields) => {
		if (search.trim().length < 3) {
			return true;
		}
		const searchStrings = search.split(" ");

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			const found = fields.reduce((isFound, field) => {
				return isFound || (row[field] && row[field].toString().toLowerCase().indexOf(search) > -1);
			}, false);
			if (found) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const ok = () => {
		setUser(selectedUser);
	};

	const resultStudents = students.filter((student) => contains(student, searchFields));

	return (
		<Window>
			<Panel title="Flugschüler wählen" size="small">
				<div className={styles.panel}>
					<div className={styles.search}>
						<SearchFilter filterText={search} setFilterText={setSearch} placeholder="Suche nach Nachname, Vorname, SHV-Nummer" />
					</div>
					<ul className={styles.list}>
						{resultStudents.map((user) => (
							<li key={user.id} className={selectedUser === user.id ? styles.selected : ""} onClick={() => setSelectedUser(user.id)}>
								{user.firstname} {user.lastname}
							</li>
						))}
					</ul>
					<div className={"buttons " + styles.buttons}>
						<button type="button" onClick={() => cancel()}>
							Abbrechen
						</button>
						<button type="button" disabled={!selectedUser} onClick={() => ok()}>
							OK
						</button>
					</div>
				</div>
			</Panel>
		</Window>
	);
};

export default UserSelector;
