import { useNavigate } from "react-router-dom";
import { ReactComponent as Alpina } from "../assets/images/alpina.svg";
import * as routes from "../shared/routes";
import styles from "./AlpinaLogo.module.css";

const AlpinaLogo = () => {
	const navigate = useNavigate();
	return (
		<div className={styles.logo}>
			<Alpina onClick={() => navigate(routes.START)} />
		</div>
	);
};

export default AlpinaLogo;
